const Formations = () => {
    return(
        <div className="formations">
            <h1>Formations</h1>
            <a href="formations/LinkedIn">LinkedIn</a>
        </div>
    )
}

export default Formations
